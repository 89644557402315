export default function IconBoxCard({ icon, desc}) {
    
    let iconClass = 'kaligentSingleIconSm ' + icon;

  return (
    <ul className="kaligentIcon">
      <li className="card kaligentCard rcornerBox">
        <div className="card-body">       
            <div className={iconClass}> 
                <span  className="kaligentIconText"
                 dangerouslySetInnerHTML={{ __html: desc }}>
                </span>
            </div>
        </div>
      </li>
    </ul>
  );
}

