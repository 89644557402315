export default function CardBoxCard({ desc }) {
  return (
    <ul className="checkmark">
      <li className="card rcornerBox">
        <div
          className="card-body margin-left-10"
          dangerouslySetInnerHTML={{ __html: desc }}
        ></div>
      </li>
    </ul>
  );
}
