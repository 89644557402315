export default function Service({ title, desc }) {
  return (
    <div className="service-block-v8 shadow p-3 mb-5 bg-body-tertiary rounded">
      <div className="service-block-desc text-center tabBox">
        <h4 className="customHeader">{title}</h4>
        <p dangerouslySetInnerHTML={{ __html: desc }}></p>
      </div>
    </div>
  );
}
