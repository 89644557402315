import { useState } from "react";

export default function Member({ image, name, title, title2, desc, desc2 }) {
  const [showMore, setShowMore] = useState(false);

  return (
    <div id="teamMember" className="tag-box rounded">
      <div className="cbp-caption margin-bottom-20">
        <div className="cbp-caption-defaultWrap">
          <ReturnFacultyImage img={image} name={name} />
        </div>
      </div>
      <div className="cbp-title-dark">
        <div className="cbp-l-grid-agency-title">{name}</div>
        <div className="cbp-l-grid-agency-desc">{title}</div>
        <div
          className="cbp-l-grid-agency-desc margin-bottom-20"
          dangerouslySetInnerHTML={{ __html: title2 }}
        ></div>

        <button className="btn btn-outline-secondary" onClick={handleMoreClick}>
          {showMore ? "Close" : "Click Bio"}
        </button>
        {showMore && (
          <div className="cbp-l-grid-agency-desc-more gx-5 margin-top-20 shadow p-3 mb-5 bg-body-tertiary rounded">
            <p className="cbp-l-grid-agency-desc-more bg-body-tertiary " dangerouslySetInnerHTML={{ __html: desc }}></p>
            <p className="cbp-l-grid-agency-desc-more2 bg-body-tertiary " dangerouslySetInnerHTML={{ __html: desc2 }}></p>
          </div>
        )}
      </div>
    </div>
  );

  function handleMoreClick() {
    setShowMore(!showMore);
  }
}

function ReturnFacultyImage({ img, name }) {
  var hasImage = img != null ? true : false;

  if (hasImage) {
    return (
      <img
        src={img}
        alt={name}
        title={name}
        className="img-responsive facultyImg"
      />
    );
  }
}
